html {
  body {
    .margin-bottom-4 {
      margin-bottom: 4px;
    }
    .margin-bottom-8 {
      margin-bottom: 8px;
    }
    .margin-bottom-12 {
      margin-bottom: 12px;
    }
    .margin-bottom-16 {
      margin-bottom: 16px;
    }
    .margin-bottom-20 {
      margin-bottom: 20px;
    }
    .margin-bottom-24 {
      margin-bottom: 24px;
    }
    .margin-bottom-32 {
      margin-bottom: 32px;
    }
    .margin-left-8 {
      margin-left: 8px;
    }
    .margin-right-4 {
      margin-right: 4px;
    }
    .margin-right-6 {
      margin-right: 6px;
    }
    .margin-right-8 {
      margin-right: 8px;
    }
    .margin-top-4 {
      margin-top: 4px;
    }
    .padding-16 {
      padding: 16px;
    }
    .padding-horizontal-16 {
      padding: 0 16px;
    }
  }
}
