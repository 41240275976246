@import "../Styles/colors.sass";

.PageLinks {
  .of-button-image {
    height: 20px;
  }
  .links-wrapper {
    position: relative;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    -webkit-transition: background-image 2s ease-in-out;
    transition: background-image 2s ease-in-out;
    .online-status {
      width: 75%;
      &.is-online {
        padding: 6px;
        border: 1px solid $onlyfans;
        background-color: $onlyfans-transparent;
        border-radius: 8px;
        .is-online-text {
          display: flex;
          align-items: center;
          justify-content: center;
          .green-dot {
            width: 10px;
            height: 10px;
            border-radius: 1000px;
            background: #35d063;
          }  
          .grey-dot {
            width: 10px;
            height: 10px;
            border-radius: 1000px;
            background: $silver;
          }
        }
      }
    }
  }
}
