
@import './_variables.sass';
@import '../node_modules/bulma/bulma.sass';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
