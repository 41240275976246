.PageLogin {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
  .image-grid,
  .login-form-wrapper {
    width: 50%;
    height: 100%;
  }
  .login-form-wrapper {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    justify-content: center;
    .MuiContainer-root {
      display: flex;
      flex-direction: column;
    }
  }
}
