// Fonts.
@import "./colors.sass";

html {
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  body {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $gray;
    font-weight: 400;
    .black {
      color: $black;
    }
    .gray {
      color: $gray;
    }
    .silver {
      color: $silver;
    }
    .teal {
      color: $teal;
    }
    .green {
      color: $green;
    }
    .blue {
      color: $blue;
    }
    .yellow {
      color: $yellow;
    }
    .red {
      color: $red;
    }
    .white {
      color: $white;
    }
    .danger {
      color: $red;
    }
    .primary {
      color: $blue;
    }
    .bold-34 {
      font-size: 2.125rem;
      font-weight: bold;
      letter-spacing: normal;
    }
    .bold-25 {
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: normal;
    }
    .medium-18 {
      font-size: 1.125rem;
      font-weight: 600;
      letter-spacing: normal;
    }
    .medium-16 {
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 0.32px;
    }
    .medium-13 {
      font-size: 0.8125rem;
      font-weight: 600;
      letter-spacing: 0.14px;
    }
    .regular-16 {
      font-size: 1rem;
      font-weight: normal;
      letter-spacing: 0.32px;
    }
    .medium-14 {
      font-size: 0.875rem;
      font-weight: 600;
      letter-spacing: 0.12px;
    }
    .regular-14 {
      font-size: 0.875rem;
      font-weight: normal;
      letter-spacing: 0.12px;
    }
    .regular-13 {
      font-size: 0.8125rem;
      font-weight: normal;
      letter-spacing: 0.14px;
    }
    .regular-11 {
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .light-34 {
      font-size: 2.125rem;
      font-weight: 200;
      letter-spacing: normal;
    }
    .light-25 {
      font-size: 1.6rem;
      font-weight: 200;
      letter-spacing: normal;
    }
  }
}
