@import "../Styles/colors.sass";

.FixedFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: $gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    width: 100%;
  }
}
