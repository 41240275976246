@import './colors.sass';

html {
  body {
    .message {
      padding: 24px;
      border: 1px solid $green;
      border-radius: 8px;
      color: $green;
      &.error {
        border-color: $red;
        color: $red;
      }
    }
    .clickable {
      cursor: pointer;
    }
    .one-third-height {
      height: 33%;
    }
    .three-quarter-width {
      width: 75%;
    }
    .full-height {
      height: 100%;
    }
    .full-width {
      width: 100%;
    }
    .flex {
      display: flex;
    }
    .flex-center {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }
    .flex-center-item {
      flex: 1 1 auto;
      position: relative;
    }
    .flex-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .flex-row {
      display: flex;
      flex-direction: row;
    }
    .flex-1 {
      flex: 1;
    }
    .fade-in-enter {
      opacity: 0;
    }
    .fade-in-enter-active {
      opacity: 1;
      transition: opacity 500ms;
    }
    .fade-in-exit {
      opacity: 1;
    }
    .fade-in-exit-active {
      opacity: 0;
      transition: opacity 500ms;
    }
    .instagram-bg {
      background-color: $instagram;
      &.button {
        &.is-primary {
          background-color: $instagram;
        }
      }
    }
    .twitter-bg {
      background-color: $twitter;
      &.button {
        &.is-primary {
          background-color: $twitter;
        }
      }
    }
    .max-width-300 {
      max-width: 300px;
    }
  }
}
